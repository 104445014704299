<template>
  <div>
    <section class="page-title text-center">
      <div class="auto-container">
        <div class="content-box">
          <h1>{{ item.name }}</h1>
          <div class="text">
            <nav class="woocommerce-breadcrumb">
              <router-link to="/">الرئيسيه</router-link
              >&nbsp;/&nbsp;{{ item.name }}
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="page-details " style="direction: rtl;text-align: right;">
      <div class="container" v-html="item.body"></div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        id: null,
        name: null,
        body: null,
        created_at: null,
        categories_id: null,
      },
    };
  },
  created() {
    const id = this.$route.params.id;
    if (id) {
      this.$http.get(`page/getById/${id}`).then(
        (res) => {
          if (!res.data.error) {
            this.item = res.data;
          }
        },
        () => {
          this.$bvToast.toast(`حدث خطأ فى الاتصال`, {
            autoHideDelay: 1500,
            variant: 'danger',
            appendToast: 'true',
            toaster: 'b-toaster-bottom-right',
          });
        },
      );
    }
  },
};
</script>
<style></style>
